/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #ffffff;
$black: #000;
$primary: #121212;
$secondary: #4f4747;
$ternary: #b7b7b7;
$border: #dddddd;
$gray: #f8f8f8;
$gray2: #a3a3a3;
$accent: #F80000;
