/*--------------------------------------------------------------
  18. Award
----------------------------------------------------------------*/
.cs_awaard.cs_style_1 {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  position: relative;
  .cs_awaard_subtitle {
    color: #ddd;
  }
  .cs_awaard_brand {
    width: 20%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .cs_awaard_info {
    width: 40%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .cs_awaard_time {
    width: 30%;
    text-align: right;
    @media (max-width: 991px) {
      width: 100%;
      font-size: 18px;
    }
  }
  .cs_award_img {
    position: absolute;
    opacity: 1;
    top: -100px;
    left: -50px;
    height: 65px !important;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s, transform 0.7s cubic-bezier(0.22, 1, 0.3, 1);
    pointer-events: none;
  }
  &:hover {
    .cs_award_img {
      //Make More Prominent
      height: 100px;
      //add filter
      filter: invert(1) sepia(1) saturate(1) hue-rotate(180deg);
      //Add transition
      transition: all 0.25s, transform 0.7s cubic-bezier(0.22, 1, 0.3, 1);
      //Add transform
      transform: rotate(360deg);
      //Add z-index
      z-index: 9;
      //add pointer-events
      pointer-events: auto;
    }
  }

  .cs_award_img img {
    height: inherit;
    width: auto;
    display: block;
    transition: all 0.25s;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
}
.cs_awaard_1_list {
  border-top: 1px solid $secondary;
  .cs_awaard.cs_style_1 {
    border-bottom: 1px solid $secondary;
    padding: 41px 0;
    .cs_awaard_time {
      text-align: right;
    }
  }
}
